<template>
	<div class="c-accordion">
		<div class="c-accordion__title" @click="onClick">
			<slot name="title"> </slot>
			<svg
				:class="[{ active: active === true }]"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<line x1="12" y1="5" x2="12" y2="19"></line>
				<line x1="5" y1="12" x2="19" y2="12"></line>
			</svg>
		</div>
		<div class="c-accordion__body">
			<transition name="slide-fade">
				<slot name="body" v-if="active"> </slot>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			active: false,
		};
	},

	methods: {
		onClick: function (e) {
			this.active = !this.active;
		},
	},
};
</script>

<style lang="scss">
.c-accordion {
	.slide-fade-enter-active {
		transition: all 200ms ease;
	}
	.slide-fade-leave-active {
		transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}
	.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateY(-10px);
		opacity: 0;
	}

	&__title {
		position: relative;
		user-select: none;
		cursor: pointer;
		padding: var(--margin);
		display: flex;
		align-items: center;

		> h4 {
			padding-right: var(--spacer);
			margin-bottom: 0;
		}

		svg {
			display: block;
			margin-left: auto;
			transition: var(--transition-time) linear;

			&.active {
				transform: rotate(45deg);
			}
		}

		&:hover,
		&:active {
			background-color: var(--color-bg-3);
		}
	}

	&__body {
		padding: var(--margin);
		height: auto;
	}
}
</style>
