<template>
	<div class="c-brands">
		<PageHeader :byline="byline" :showMenu="false">
			<template v-slot:title>
				{{ tagline }}
				<a class="btn btn-icon btn-primary" @click="onAddBrand">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12 12V8M12 12V16M12 12H16M12 12H8M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23Z"
							stroke="currentColor"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</svg>

					<span> Track brand </span>
				</a>
			</template>
			<template v-slot:menu>
				<router-link class="active" to="/brands">
					<h6>Brands</h6>
				</router-link>
			</template>
		</PageHeader>
		<router-view> </router-view>
		<LibraryList v-if="init" ref="list"></LibraryList>
	</div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header';
import LibraryList from './list';
import api from '@swipekit/lib/api';

export default {
	name: 'Multiplayer',

	components: {
		PageHeader,
		LibraryList,
	},

	data: function () {
		return {
			init: false,
		};
	},

	props: {
		standalone: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		byline: function () {
			return 'Brands';
		},
		tagline: function () {
			return `Get insights on tracked brands`;
		},
		fullCleanPath: function () {
			let path = this.$route.fullPath;

			path = path.split('?')[0];

			return path;
		},
		currentPage: function () {
			let route = this.$route;

			if (route.path === '/multiplayer') {
				return 'multiplayer';
			}
			if (route.path === '/multiplayer/landing-pages') {
				return 'landing-pages';
			}
			return '';
		},
	},

	methods: {
		onEnd: function () {
			if (!this.$refs.list) {
			} else {
				this.$refs.list.onEnd();
			}
		},
		onAddBrand: function () {
			this.$store.dispatch('app/setAddBrand', true);
		},
	},

	mounted: function () {
		this.init = true;
	},
};
</script>

<style lang="scss">
.c-brands {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 320px;
	}
}
</style>
