<template>
	<div class="c-brand-slug-breakdown">
		<strong> Creative breakdown </strong>
		<div>
			<span class="bg" :style="computedStyle('video')"></span>
			<span> Video </span>
			<span> {{ insight.assetTypes.video }} ({{ calculatePercentage('video') }}%) </span>
		</div>
		<div>
			<span class="bg" :style="computedStyle('image')"></span>
			<span> Statics </span>
			<span> {{ insight.assetTypes.image }} ({{ calculatePercentage('image') }}%) </span>
		</div>
		<div>
			<span class="bg" :style="computedStyle('carousel')"></span>
			<span> Carousel </span>
			<span> {{ insight.assetTypes.carousel }} ({{ calculatePercentage('carousel') }}%) </span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		insight: {},
	},

	methods: {
		calculatePercentage: function (param) {
			let data = this.insight.assetTypes;
			// Calculate total count
			const totalCount = Object.values(data).reduce((total, count) => total + count, 0);

			// Calculate percentages
			const percentages = {};
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					percentages[key] = ((data[key] / totalCount) * 100).toFixed(2);
				}
			}

			for (const key in percentages) {
				if (isNaN(percentages[key])) {
					percentages[key] = 0;
				}
			}

			if (param) {
				return percentages[param];
			}

			return percentages;
		},
		computedStyle: function (param) {
			let percentage = this.calculatePercentage(param);

			return {
				width: `${percentage}%`,
			};
		},
	},
};
</script>

<style lang="scss">
.c-brand-slug-breakdown {
	margin-top: var(--margin-lg);

	strong {
		display: block;
		margin-bottom: var(--margin);
	}

	> div {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 128px;
		padding: var(--margin-sm);
		border-radius: 4px;
		border: var(--color-bg-3) solid 1px;
		font-size: var(--font-size-sm);
		font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0, 'cv01' 0,
			'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl', 'kern';

		&:not(:last-child) {
			margin-bottom: var(--margin);
		}

		> .bg {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: var(--color-primary);
			opacity: 0.075;
			border-radius: 4px;
		}

		span:last-child {
			text-align: right;
		}
	}
}
</style>
