<template>
	<div class="c-brand-slug-ads">
		<!-- <SearchBar :multiplayer="true" :activeCategories="multiplayerCategories" v-model="params" @search="onSearch" @view="onView"></SearchBar> -->
		<div class="c-brand-slug-ads__group" v-for="(group, i) in computedBlocks" :key="i">
			<header>
				<h3>{{ group.blocks.length }} Ad{{ showS(group.blocks.length) }} saved on {{ formatDate(group.startDate) }}</h3>
			</header>
			<div v-masonry="containerId" transition-duration="0.3s" item-selector=".c-grid-item">
				<Item v-for="block in group.blocks" :multiplayer="true" :ad="block" :key="block.id" :view="view" @onSubmit="onSubmit" v-masonry-tile></Item>
			</div>
		</div>
		<!-- <div v-masonry="containerId" transition-duration="0.3s" item-selector=".c-grid-item">
			<Item v-for="block in computedBlocks" :multiplayer="true" :ad="block" :key="block.id" :view="view" @onSubmit="onSubmit" v-masonry-tile></Item>
		</div> -->
		<div class="c-brand-slug-ads__empty-state" v-if="end && blocks.length > 0">
			<div class="cbrand-slug-ads__empty-state__inner">
				<h5>End of results</h5>
			</div>
		</div>
		<div class="c-brand-slug-ads__empty-state" v-if="blocks.length === 0 && !processing">
			<div class="c-brand-slug-ads__empty-state__inner">
				<h5>No Ads found.</h5>
				<p>Try using other filters.</p>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBar from '@swipekit/components/utils/search-bar';
import Item from '@swipekit/components/item';

import filter from '@swipekit/lib/filter';
import { getParams, setParams } from '@swipekit/lib/query-param-utils';

import api from '@swipekit/lib/api';
import renderDate from '@swipekit/lib/render-date';

export default {
	components: {
		SearchBar,
		Item,
	},

	data: function () {
		return {
			containerId: null,
			params: {
				query: '',
				network: 'all',
				mediaType: 'all',
				activeAds: 0,
				categories: [],
				brands: [],
			},
			blocks: [],
			skip: 0,
			take: 0,

			end: false,

			view: '',

			slug: null,

			adIds: [],
		};
	},

	watch: {
		$route: function () {},
		refreshFilters: function () {
			this.filter();
		},
	},

	computed: {
		multiplayerCategories: function () {
			return this.$store.getters['app/multiplayerCategories'];
		},
		refreshFilters: function () {
			return this.$store.getters['app/refreshFilters'];
		},
		computedBlocks: function () {
			let blocks = this.blocks;

			if (!blocks) {
				return [];
			}

			let ids = this.adIds;

			for (let i = 0; i < blocks.length; i++) {
				let isSaved = false;

				if (ids.includes(blocks[i].id)) {
					isSaved = true;
				}

				blocks[i].isSaved = isSaved;
			}

			blocks = blocks.map((b) => {
				return {
					block: b,
				};
			});

			let groups = this.groupObjectsByStartDate(blocks);

			//console.log(blocks[0]);

			return groups.dates;

			return blocks;
		},
		processing: function () {
			return this.$store.getters['app/processing'];
		},
	},

	methods: {
		showS: function (len) {
			if (len === 1) {
				return '';
			}
			return 's';
		},
		formatDate(date) {
			return renderDate(date);
		},
		groupObjectsByStartDate(objects) {
			// Initialize an empty object to store the grouped results
			let groupedByStartDate = {
				dates: [],
			};

			// Iterate over each object in the input array
			objects.forEach((obj) => {
				const dateOnly = obj.block.createdAt.split('T')[0];

				// Check if there's already an entry for this startDate in groupedByStartDate
				let existingDateEntry = groupedByStartDate.dates.find((dateEntry) => {
					return dateEntry.startDate === dateOnly;
				});

				if (existingDateEntry) {
					// If startDate already exists, add the current object to its blocks array
					existingDateEntry.blocks.push(obj);
				} else {
					// If startDate doesn't exist, create a new entry
					groupedByStartDate.dates.push({
						startDate: dateOnly,
						blocks: [obj],
					});
				}
			});

			return groupedByStartDate;
		},
		async onView(view) {
			this.view = view;

			api.api.setOption('view', view);

			this.$nextTick(() => {
				this.$redrawVueMasonry();
			});
		},
		onSubmit: function (e) {
			this.$emit('onSubmit', e);
		},
		applyParams: function (params) {
			if (params.query) {
				this.params.query = params.query;
			}

			if (params.skip) {
				this.skip = params.skip;
			}
			if (params.take) {
				this.take = params.take;
			}

			if (params.categories) {
				this.params.categories = params.categories;
			}

			if (params.brands) {
				this.params.brands = params.brands;
			}

			if (params.mediaType) {
				this.params.mediaType = params.mediaType;
			}

			if (params.activeAds) {
				this.params.activeAds = params.activeAds;
			}

			if (params.network) {
				this.params.network = params.network;
			}
		},
		onEnd: function () {
			if (this.processing) {
				return;
			}
			if (this.end) {
				return;
			}
			this.skip += this.take;
			this.filter();
		},
		async onSearch(params) {
			this.blocks = [];
			this.skip = 0;
			this.end = false;
			await this.filter(params);
		},
		async filter(params, firstTime = false) {
			if (this.processing) {
				return;
			}
			this.$store.dispatch('app/setProcessing', true);

			if (firstTime) {
				params = getParams();

				// if first time, force 1st page
				params.skip = 0;

				this.applyParams(params);
			}
			if (!params) {
				params = getParams();
			}

			params.skip = this.skip;
			params.take = this.take;

			setParams(params);

			const blocks = await filter(params, `filter-brand-ads/${this.slug}`);

			if (blocks.length < this.take) {
				this.end = true;
			}
			this.blocks = this.blocks.concat(blocks);

			this.$nextTick(() => {
				this.$redrawVueMasonry();
				this.$store.dispatch('app/setProcessing', false);
			});
		},
		async getIds() {
			let ids = await api.ads.getAdIds();

			this.adIds = ids;

			this.init = true;
		},
	},

	mounted: async function () {
		this.getIds();
		this.slug = this.$route.params.slug;
		this.skip = 0;
		this.take = 40;
		this.filter(null, true);

		let options = await api.api.getOptions();

		this.view = options.view || '';
	},
};
</script>

<style lang="scss">
.c-brand-slug-ads {
	&__empty-state {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		&__inner {
			display: inline-block;
			margin: 0 auto;
			border: var(--color-bg-4) solid 1px;
			border-radius: 10px;
			padding: var(--spacer-sm);

			h5 {
				margin-bottom: 0;
			}

			p {
				margin-bottom: 0;
			}
		}
	}
	&__empty-state {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		&__inner {
			display: inline-block;
			margin: 0 auto;
			border: var(--color-bg-4) solid 1px;
			border-radius: 10px;
			padding: var(--spacer-sm);

			p {
				margin-bottom: 0;
			}
		}
	}

	&__group {
		position: relative;
		z-index: 1;

		> header {
			padding: var(--spacer-sm);
			background-color: var(--color-bg-3);

			h3 {
				font-family: var(--font-family);
				margin: 0;
				font-weight: 500;
				opacity: 0.95;
				text-transform: initial;
				letter-spacing: 0;
			}
		}
	}
}
</style>
