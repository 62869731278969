<template>
	<div class="c-modal-add-brand">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<template v-slot:title>
				<h2>Track a brand</h2>
			</template>
			<template>
				<p class="article-text">Add a brand from the Facebook Ad library and <strong>we will track it for you.</strong></p>
				<ul class="c-list-fancy">
					<li>No need to go to the Facebook Ad library to save the Ads, you will get new ads from this ad in your tracked brands area.</li>
					<li>We will generate valuable insights for this brand and keep it updated.</li>
					<li>We will also save screenshots of this brand's landing pages and keep track of them.</li>
				</ul>
				<hr />
				<p>
					To add a brand, copy the url of the brand page and paste it here. Here's an
					<a href="https://www.facebook.com/ads/library/?active_status=active&ad_type=all&country=ALL&media_type=all&search_type=page&view_all_page_id=1978160732305312" target="_blank">example</a> of
					a brand page.
				</p>
				<FormBrandCreate @onSubmit="onSubmit"></FormBrandCreate>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';
import FormBrandCreate from '@swipekit/components/form/form-brand-create.vue';
export default {
	components: {
		Modal,
		FormBrandCreate,
	},

	data: function () {
		return {
			options: {
				active: false,
			},
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		addBrand: function () {
			if (this.addBrand) {
				this.options.active = true;
			} else {
				this.options.active = false;
			}
		},
	},

	computed: {
		addBrand: function () {
			return this.$store.getters['app/addBrand'];
		},
	},

	methods: {
		onSubmit: function () {
			this.options.active = false;
		},
		activate: function () {
			this.options.active = true;
		},
		closeModal: function () {
			this.$store.dispatch('app/setAddBrand', false);
		},
	},

	mounted: function () {
		//this.options.active = true;
	},
};
</script>

<style lang="scss">
.c-modal-add-brand {
	.modal__content {
		strong {
			font-weight: 400;
			text-decoration: underline;
		}
	}
}
</style>
