<template>
	<div ref="item" :class="['c-brands-item']">
		<router-link class="c-brands-item__wrapper" :to="`/brands/${item.slug}`">
			<div class="c-brands-item__inner">
				<img :src="`${assetUrl}/${item.logo}`" />
				<span>
					{{ item.name }}
				</span>
				<span> {{ item.blockCount }} Ads </span>
			</div>
		</router-link>
	</div>
</template>

<script>
// import FormSaveBlock from "@swipekit/components/form/form-save-block.vue";

export default {
	components: {
		// FooterComponent,
		// ImageComponent,
		// Metadata,
		// FormSaveBlock,
	},

	props: {
		item: {},
		standalone: {
			type: Boolean,
			default: false,
		},
		shared: {
			type: Boolean,
			default: false,
		},
		multiplayer: {
			type: Boolean,
			default: false,
		},
		view: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	computed: {
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
	},
};
</script>

<style lang="scss">
.c-brands-item {
	width: calc(var(--header-width) + 360px);
	margin: 0 auto;
	margin-bottom: var(--margin);
	height: auto;

	&__wrapper {
		min-height: 68px;
		position: relative;
		display: block;
		color: var(--color-font);
		background-color: var(--color-bg-3);
		border-radius: 10px;

		&:hover,
		&:active {
			background-color: var(--color-bg-4);
		}
	}

	&__inner {
		padding: var(--margin-lg);

		display: grid;
		grid-template-columns: 48px 240px 1fr;
		grid-column-gap: var(--margin);
		align-items: center;

		img {
			width: 100%;
			height: 48px;
			object-fit: cover;
			border-radius: 100%;
		}

		span {
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
</style>
