<template>
	<div class="c-brand-slug-networks">
		<strong> Common networks </strong>
		<article v-for="(network, i) in networks" :key="i">
			<span>
				{{ network.name }}
			</span>
			<span> {{ network.count }} ({{ calculatePercentage(network.name) }}%) </span>
		</article>
	</div>
</template>

<script>
import Copy from '@swipekit/components/block-view/copy';
export default {
	components: {
		Copy,
	},

	props: {
		insight: {},
	},

	computed: {
		networks: function () {
			let networks = this.insight.networks || {};
			let arr = [];
			for (const key in networks) {
				arr.push({
					name: key,
					count: networks[key],
				});
			}

			return arr;
		},
	},

	methods: {
		calculatePercentage: function (param) {
			let data = this.insight.networks;
			// Calculate total count
			const totalCount = Object.values(data).reduce((total, count) => total + count, 0);

			// Calculate percentages
			const percentages = {};
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					percentages[key] = ((data[key] / totalCount) * 100).toFixed(2);
				}
			}

			for (const key in percentages) {
				let count = parseInt(percentages[key]);

				if (isNaN(count)) {
					percentages[key] = 0;
				} else {
					percentages[key] = count;
				}
			}

			if (param) {
				return percentages[param];
			}

			return percentages;
		},
	},
};
</script>

<style lang="scss">
.c-brand-slug-networks {
	margin-top: var(--margin-lg);

	.c-block-view-copy {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: var(--margin-sm);
	}

	article {
		display: grid;
		grid-template-columns: 1fr 128px;
		align-items: center;
		padding: var(--margin-sm);

		&:hover {
			background-color: var(--color-bg-3);
		}

		span:last-child {
			text-align: right;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';
		}
	}

	strong {
		display: block;
		margin-bottom: var(--margin);
	}
}
</style>
