<template>
	<div class="c-brands-page" v-if="isAuth" @scroll="onScroll">
		<BrandsSlug></BrandsSlug>
	</div>
</template>

<script>
import BrandsSlug from '@swipekit/components/brand-slug';

export default {
	components: {
		BrandsSlug,
	},

	computed: {
		isAuth: function () {
			return this.$store.getters['users/isAuth'];
		},
		standalone: function () {
			return this.$store.getters['standalone'];
		},
	},

	methods: {
		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			// let buffer = 200;
			// if (scrollTop + clientHeight >= scrollHeight - buffer) {
			// 	this.$refs.multi.onEnd();
			// } else {
			// }
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-brands-page {
	height: 100vh;
	position: relative;
	overflow-y: auto;
}
</style>
