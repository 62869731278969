<template>
	<div class="c-brands-insight">
		<section>
			<header>
				<img :src="`${assetUrl}/${brand.logo}`" />
				<article>
					<h3>{{ brand.name }}</h3>
					<span>{{ brand.category }} / {{ brand.id }}</span>
				</article>
			</header>
		</section>
		<section>
			<h3>Ad statistics</h3>
			<div class="c-brands-insight__duration">
				<span @click="setDuration(0)" :class="[{ active: days === 0 }]"> Lifetime </span>
				<span @click="setDuration(7)" :class="[{ active: days === 7 }]"> 7 Days </span>
				<span @click="setDuration(30)" :class="[{ active: days === 30 }]"> 30 Days </span>
			</div>
			<template v-if="currentInsight">
				<Breakdown :insight="currentInsight"></Breakdown>
				<Links :insight="currentInsight"></Links>
				<Words :insight="currentInsight"></Words>
				<Networks :insight="currentInsight"></Networks>
			</template>
			<blockquote v-else>
				<p>Insights haven't been calculated for this brand yet. Please wait for a few days for insights to appear.</p>
			</blockquote>
		</section>
	</div>
</template>

<script>
import Breakdown from './breakdown';
import Links from './links';
import Words from './words';
import Networks from './networks';

export default {
	components: {
		Breakdown,
		Links,
		Words,
		Networks,
	},

	props: {
		brand: {},
	},

	data: function () {
		return {
			days: 0,
		};
	},

	computed: {
		currentInsight: function () {
			if (!this.brand.insight) {
				return;
			}
			if (!this.days) {
				return this.brand.insight.lifetime;
			}
			if (this.days === 7) {
				return this.brand.insight['7days'];
			}
			if (this.days === 30) {
				return this.brand.insight['30days'];
			}
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
	},

	methods: {
		setDuration: function (days) {
			if (!days) {
				this.days = 0;
			} else {
				this.days = days;
			}
		},
	},
};
</script>

<style lang="scss">
.c-brands-insight {
	background-color: var(--color-bg-1);
	width: calc(var(--header-width) + 360px);
	margin: var(--spacer-sm) auto;

	box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(9, 9, 11, 0.08) 0px 0px 0px 1px, rgba(9, 9, 11, 0.08) 0px 1px 2px -1px, rgba(9, 9, 11, 0.04) 0px 2px 4px 0px;
	border-radius: 20px;

	&__duration {
		display: inline-block;
		border: rgba(9, 9, 11, 0.1) solid 1px;
		border-radius: 8px;
		padding: 4px;

		span {
			display: inline-block;
			padding: var(--margin) var(--margin-lg);
			border-radius: 4px;
			font-size: var(--font-size-sm);
			line-height: 1;
			user-select: none;
			cursor: pointer;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';

			&.active {
				background-color: var(--color-primary);
				color: #fff;
			}

			&:hover {
				&:not(.active) {
					background-color: var(--color-primary-light);
				}
			}
		}
	}

	section {
		padding: var(--spacer-sm);

		&:not(:last-child) {
			border-bottom: rgba(9, 9, 11, 0.1) solid 1px;
		}
	}

	header {
		display: grid;
		grid-template-columns: 64px 1fr;
		grid-column-gap: var(--spacer-sm);
		align-items: center;

		img {
			width: 64px;
			height: 64px;
			background-color: cyan;
			border-radius: 100%;
			overflow: hidden;
			object-fit: cover;
		}
	}

	h3 {
		font-family: var(--font-family);
	}
}
</style>
