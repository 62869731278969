<template>
	<div class="c-library" @scroll="onScroll">
		<PageHeader byline="Library" :showMenu="false">
			<template v-slot:title> All your ads, in one place </template>

			<template v-slot:menu>
				<router-link :class="[{ active: fullCleanPath === '/' }]" :to="`/`">
					<h6>Saved Ads</h6>
				</router-link>
				<router-link :class="[{ active: fullCleanPath === '/brands' }]" :to="`/brands`"> <h6>Tracked brands</h6> </router-link>
			</template>
		</PageHeader>
		<LibraryList v-if="init" ref="list" :adIds="adIds" @onSubmit="onSave"></LibraryList>
	</div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header';
import LibraryList from './list';
import api from '@swipekit/lib/api';

export default {
	name: 'Multiplayer',

	components: {
		PageHeader,
		LibraryList,
	},

	data: function () {
		return {
			init: false,
			adIds: [],
		};
	},

	props: {
		standalone: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		fullCleanPath: function () {
			if (this.$route.name === 'Home') {
				return '/';
			}

			return '/brands';
		},
	},

	methods: {
		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			let buffer = 100;
			if (scrollTop + clientHeight >= scrollHeight - buffer) {
				this.onEnd();
			} else {
			}
		},
		onSave: function (e) {
			const blockId = e.blockId;

			this.$store.dispatch('ads/refreshAll');

			this.adIds.push(blockId);
		},
		onEnd: function () {
			this.$refs.list.onEnd();
		},
		async getIds() {
			let ids = await api.ads.getAdIds();

			this.adIds = ids;

			this.init = true;
		},
	},

	mounted: function () {
		this.getIds();
	},
};
</script>

<style lang="scss">
.c-library {
	height: 100vh;
	position: relative;
	overflow-y: auto;
}
</style>
