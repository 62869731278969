<template>
	<div class="c-brands-slug-ad-list" v-if="isAuth" @scroll="onScroll">
		<AdList></AdList>
	</div>
</template>

<script>
import AdList from '@swipekit/components/brand-slug/ad-list';

export default {
	components: {
		AdList,
	},

	computed: {
		isAuth: function () {
			return this.$store.getters['users/isAuth'];
		},
		standalone: function () {
			return this.$store.getters['standalone'];
		},
	},

	methods: {
		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			// let buffer = 200;
			// if (scrollTop + clientHeight >= scrollHeight - buffer) {
			// 	this.$refs.multi.onEnd();
			// } else {
			// }
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-brands-slug-ad-list {
}
</style>
